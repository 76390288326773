<template>
  <div class="d-flex flex-column flex-lg-row">
    <portal to="subheader-left">
      <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <h5 class="text-dark font-weight-bold my-5 mr-5">
          WBSCGI-Anfragen für Flugverfügbarkeitscontrolling
        </h5>
        <div class="subheader-separator subheader-separator-ver mt-4 mb-2 mr-5 bg-gray-200"></div>
      </div>
    </portal>

    <div class="card card-filter-nav d-flex flex-column card-custom">
      <div class="card-body overflow-y-auto">
        <div class="d-flex justify-content-center">
          <div class="col md-6">
            <textarea class="w-100 rounded" v-model="csv" rows="20" :placeholder="placeholder"></textarea>
            <b-button @click="send" block :disabled="!csv" variant="primary" class="mt-2"
              >WBSCGI-Anfrage starten</b-button
            >
          </div>
          <div class="col md-6">
            <div class="alert alert-custom alert-light-primary font-weight-bold">
              <div class="alert-text">
                Antwort bei einigen wenigen Zeilen ca. 2-5 Minuten. Bei größeren Anfragen kann es mehrere
                Stunden dauern.
              </div>
            </div>
            <div class="border rounded p-3">
              <div v-if="message" class="mt-4">
                ------------------------------------
                <h3>{{ message }}</h3>
                <p>
                  Ausgabe umgeleitet in
                  <a
                    href="https://docs.google.com/spreadsheets/d/1CWsIAagZ36RXFdppgl58KQ8YRuRqZHMGOTedJNIauBI/edit#gid=0"
                    >Flugverfügbarkeitsabfrage-Spreadsheet</a
                  >
                </p>
              </div>
              <div v-else-if="isLoading">
                <LoadSpinnerWidget :isLoading="isLoading" :dataAvailable="message"></LoadSpinnerWidget>
              </div>
              <div v-else>Warte auf Anfrage...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';

export default {
  components: { LoadSpinnerWidget },

  data() {
    return {
      isLoading: false,
      csv: '',
      message: '',
      placeholder: `Hier mit Komma getrennte CSV Datensätze einfügen:

Reiseterminkürzel,LEIST-VONDAT-1,LEIST-BISDAT-1,LEIST-F-AN3L-H-1,LEIST-F-AB3L-R-1,LEIST-VONDAT-2,LEIST-BISDAT-2,LEIST-H-HOTEL-2,PAX,LEIST-H-ZIMMER-2
ITAROM_2021-10-02,02.10.2021,09.10.2021,FCO,FCO,02.10.2021,09.10.2021,ITAROM,1,DZ
ITAROM_2021-10-02,02.10.2021,09.10.2021,FCO,FCO,02.10.2021,09.10.2021,ITAROM,1,DZ
 `,
    };
  },
  methods: {
    send() {
      this.isLoading = true;
      this.message = '';
      //TODO url string in config?
      fetch(
        'https://skr3-blank-functions.azurewebsites.net/api/flugverfuegbarkeitsabfrage-http?code=ImKYdKABgaP6AFelnneaUD29rM/J/SUG/GxyW0TVadgVGTXrchQZGQ==',
        {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'no-cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'omit', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(this.csv), // body data type must match "Content-Type" header
        }
      )
        .then(response => {
          this.message = 'Abfrage erfolgreich abgeschickt.';
        })
        .catch(err => {
          this.message = 'Abfrage ist fehlgeschlagen';
          console.error(JSON.stringify(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
