<template>
  <div>
    <span v-if="!dataAvailable && !isLoading && showText">{{ $t('COMMON.NODATAFOUND') }}</span>
    <div class="d-flex align-items-center" v-if="isLoading">
      <div class="spinner spinner-dark"></div>
      <div class="text-muted ml-10" v-if="showText">{{ $t('COMMON.DATENWERDENGELADEN') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'load-spinner-widget',
  props: {
    dataAvailable: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
